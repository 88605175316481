import GroupDirectoryAppSettingsModal from "@/apps/list/app/group-directory/GroupDirectoryAppSettingsModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { GlobalID } from "@/relay/RelayTypes"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"

interface GroupDirectoryAppSettingsButtonProps extends TestIDProps {
  children?: OverridableDiscoButtonChildren
  appId: GlobalID
}

export default function GroupDirectoryAppSettingsButton({
  appId,
  children = "Manage",
  testid = "GroupDirectoryAppSettingsButton",
}: GroupDirectoryAppSettingsButtonProps) {
  const { openModalState, setOpenModalState } = useActiveAppModal()

  return (
    <>
      <OverridableDiscoButton onClick={openModal} testid={testid}>
        {children}
      </OverridableDiscoButton>

      {openModalState?.id === appId && <GroupDirectoryAppSettingsModal appId={appId} />}
    </>
  )

  function openModal() {
    setOpenModalState({
      kind: "group_directory",
      id: appId,
    })
  }
}
