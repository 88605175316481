import GroupDirectoryAppSettingsForm from "@/apps/list/app/group-directory/GroupDirectoryAppSettingsForm"
import SetupAppModal from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { GlobalID } from "@/relay/RelayTypes"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"

type Props = TestIDProps & {
  appId?: GlobalID
}

function GroupDirectoryAppSettingsModal({
  testid = "GroupDirectoryAppSettingsModal",
  appId,
}: Props) {
  const { openModalState, closeModal, setOpenModalState } = useActiveAppModal()

  return (
    <SetupAppModal
      testid={`${testid}.group-directory`}
      isModalOpen={openModalState?.kind === "group_directory"}
      title={`${appId ? "Edit" : "Add"} Group Directory App`}
      description={"Configure the group directory app."}
      body={
        <GroupDirectoryAppSettingsForm
          testid={testid}
          appId={appId}
          onClose={closeModal}
          onBack={appId ? closeModal : () => setOpenModalState({ kind: "add-app" })}
        />
      }
    />
  )
}

export default observer(GroupDirectoryAppSettingsModal)
