/**
 * @generated SignedSource<<556426abcf2a55d6ec26008b3f343be1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "events" | "group_directory" | "link" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RemoveAppButtonFragment$data = {
  readonly collection: {
    readonly id: string;
  } | null;
  readonly customAppTitle: string | null;
  readonly feed: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly navSectionId: string | null;
  readonly organizationId: string;
  readonly productId: string | null;
  readonly " $fragmentType": "RemoveAppButtonFragment";
};
export type RemoveAppButtonFragment$key = {
  readonly " $data"?: RemoveAppButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveAppButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveAppButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Feed",
      "kind": "LinkedField",
      "name": "feed",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Collection",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "navSectionId",
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "f0bb283641fa019254d9f3885b1b1776";

export default node;
