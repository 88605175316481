/**
 * @generated SignedSource<<d33b2fbfc335f1f47b61bbaed3ba0fab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductAppKind = "chat_channel" | "collection" | "content" | "curriculum" | "events" | "group_directory" | "link" | "members" | "nav_folder" | "organization_events" | "organization_members" | "posts" | "product_link" | "resources" | "slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type LinkAppSetupModalFragment$data = {
  readonly id: string;
  readonly kind: ProductAppKind;
  readonly " $fragmentType": "LinkAppSetupModalFragment";
};
export type LinkAppSetupModalFragment$key = {
  readonly " $data"?: LinkAppSetupModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LinkAppSetupModalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkAppSetupModalFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "9f737479901560a1940943c823f23b7a";

export default node;
