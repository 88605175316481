/**
 * @generated SignedSource<<981a676df4c43d2cb09749100ada3d63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteChatChannelAppButtonFragment$data = {
  readonly chatChannel: {
    readonly id: string;
    readonly organizationId: string;
  } | null;
  readonly id: string;
  readonly navSectionId: string | null;
  readonly product: {
    readonly id: string;
    readonly slug: string;
  } | null;
  readonly " $fragmentType": "DeleteChatChannelAppButtonFragment";
};
export type DeleteChatChannelAppButtonFragment$key = {
  readonly " $data"?: DeleteChatChannelAppButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteChatChannelAppButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteChatChannelAppButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "navSectionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatChannel",
      "kind": "LinkedField",
      "name": "chatChannel",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "organizationId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
})();

(node as any).hash = "bbec6ff0a17941cd57af98655362d303";

export default node;
