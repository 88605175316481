import AppSettingsForm from "@/apps/list/app/common/settings/AppSettingsForm"
import ContentAppSelectContent from "@/apps/list/app/content/modal/ContentAppSelectContent"
import AppEmbedCodeFormSection from "@/apps/list/form-sections/AppEmbedCodeFormSection"
import AppUrlFormSection from "@/apps/list/form-sections/AppUrlFormSection"
import { AppIconKind, useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { ContentAppKind } from "@/apps/util/appConstants"
import { useAppLevel } from "@/apps/util/appLevelContext"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import { ContentTemplateKind } from "@/content/util/contentTemplates"
import { GlobalID } from "@/relay/RelayTypes"
import EditorUtils from "@components/editor/EditorUtils"
import { $createEmbedNode, EmbedKind } from "@components/editor/plugins/embeds/EmbedNode"
import UploadScormFileDropzone from "@components/editor/plugins/embeds/scorm/UploadScormFileDropzone"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { $getRoot } from "lexical"
import { observer } from "mobx-react-lite"
import { useState } from "react"

export type CreateContentAppStateData = {
  name: string
  icon: AppIconKind
  description?: string
  placeholder?: string
  warningText?: string
}

interface Props {
  appId?: GlobalID
  createStateData?: CreateContentAppStateData
}

function ContentAppSetupModalContent({ appId, createStateData }: Props) {
  const { navFolderId, navSectionId } = useAppLevel()
  const { openModalState, closeModal, setOpenModalState } = useActiveAppModal()
  const contentUsageDrawer = useContentUsageDrawer()

  const createForm = useAddAppFormStore({
    kind: "content",
    customAppTitle: createStateData?.name,
    navFolderId,
    navSectionId,
    visibility: "all",
    visibilityGroups: [],
    visibilityMembers: [],
    badge: {
      kind: "icon",
      icon: createStateData?.icon,
      color: "#ffffff00",
    },
  })
  const editForm = useEditAppFormStore(appId)

  const codeEmbedApps: ContentAppKind[] = ["discord", "miro", "whereby", "custom_embed"]
  const linkEmbedApps: ContentAppKind[] = [
    "airtable",
    "figma",
    "google_doc",
    "google_form",
    "typeform",
  ]

  const [step, setStep] = useState<"setup-app" | "select-content">("setup-app")
  const creatingPageApp = openModalState?.contentAppKind === "page" && !appId

  if (!appId && step === "select-content") {
    return <ContentAppSelectContent form={createForm} handleAddApp={handleAddApp} />
  }
  return (
    <AppSettingsForm
      form={appId ? editForm : createForm}
      onSubmit={appId ? handleEditApp : handleAddApp}
      onClose={appId ? closeModal : () => setOpenModalState({ kind: "add-app" })}
      onNext={creatingPageApp ? () => setStep("select-content") : undefined}
      mode={appId ? "edit" : "add"}
      formSectionBody={
        <>
          {/* Only show this section when creating */}
          {!appId &&
            openModalState?.contentAppKind &&
            codeEmbedApps.includes(openModalState.contentAppKind) && (
              <AppEmbedCodeFormSection
                form={appId ? editForm : createForm}
                placeholder={createStateData?.placeholder}
                warningText={createStateData?.warningText}
              />
            )}
          {!appId &&
            openModalState?.contentAppKind &&
            linkEmbedApps.includes(openModalState.contentAppKind) && (
              <AppUrlFormSection
                label={`${createStateData?.name} URL`}
                form={appId ? editForm : createForm}
                placeholder={createStateData?.placeholder}
              />
            )}
          {!appId && openModalState?.contentAppKind === "scorm" && (
            <UploadScormFileDropzone
              onUpload={handleUploadScormFile}
              onRemove={handleRemoveScormFile}
            />
          )}
        </>
      }
    />
  )

  function handleUploadScormFile(scormFileId: GlobalID) {
    createForm.state.scormFileId = scormFileId
  }
  function handleRemoveScormFile() {
    createForm.state.scormFileId = undefined
  }

  async function handleAddApp(opts?: { contentTemplate?: ContentTemplateKind }) {
    const { contentTemplate } = opts || {}

    try {
      const { contentAppKind } = openModalState!
      if (!contentAppKind) throw new Error("Missing contentAppKind in handleAddApp")

      let richEditorContent = JSON.stringify(EditorUtils.createParagraphs([""]))

      if (contentAppKind === "scorm") {
        const editor = EditorUtils.createEditor()

        editor.update(
          () => {
            $getRoot().append(
              $createEmbedNode({
                kind: "scorm",
                data: {
                  scormFileId: createForm.state.scormFileId,
                },
              })
            )
          },
          { discrete: true }
        )

        richEditorContent = JSON.stringify(editor.getEditorState().toJSON())
      } else if (codeEmbedApps.includes(contentAppKind)) {
        const editor = EditorUtils.createEditor()

        editor.update(
          () => {
            $getRoot().append(
              $createEmbedNode({
                kind: contentAppKind === "custom_embed" ? "embed_code" : "generic_iframe",
                data: {
                  html: createForm.state.embedCode,
                },
              })
            )
          },
          { discrete: true }
        )

        richEditorContent = JSON.stringify(editor.getEditorState().toJSON())
      } else if (linkEmbedApps.includes(contentAppKind)) {
        const editor = EditorUtils.createEditor()

        editor.update(
          () => {
            $getRoot().append(
              $createEmbedNode({
                kind:
                  // The kind needs to match the kind of the different kinds of embed blocks
                  contentAppKind === "google_doc"
                    ? "google_docs"
                    : contentAppKind === "google_form"
                    ? "google_forms"
                    : (contentAppKind as EmbedKind),
                data: {
                  url: createForm.state.customAppUrl ?? undefined,
                },
              })
            )
          },
          { discrete: true }
        )

        richEditorContent = JSON.stringify(editor.getEditorState().toJSON())
      }

      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        ...state
      } = createForm.state
      const { didSave, response } = await createForm.submit(
        {
          ...state,
          customAppUrl: undefined,
          showOnDashboard: createForm.state.showOnDashboard,
          richEditorContent,
        },
        {
          connections: [],
          variables: { isOrgTopLevel: !state.productId && !state.navSectionId },
        }
      )
      if (!didSave) return

      displaySuccessToast({
        message: `${createStateData?.name} app created!`,
        testid: "AddApp.success-toast",
      })
      closeModal()
      openAppDrawer(response?.node?.contentUsage?.id, contentTemplate)
    } catch (error) {
      displayErrorToast(error)
    }
  }

  async function handleEditApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        kind: _kind,
        ...changedState
      } = editForm.changedState
      const { didSave, response } = await editForm.submit({
        id: editForm.state.id,
        ...changedState,
        badge: editForm.state.badge,
        showOnDashboard: editForm.state.showOnDashboard,
        visibility: editForm.state.visibility,
        visibilityGroups: editForm.state.visibilityGroups,
        visibilityMembers: editForm.state.visibilityMembers,
      })
      if (!didSave) return
      displaySuccessToast({
        message: "Updated app!",
        testid: "AddApp.success-toast",
      })
      closeModal()
      openAppDrawer(response?.node?.contentUsage?.id)
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function openAppDrawer(
    contentUsageId?: GlobalID,
    contentTemplate?: ContentTemplateKind
  ) {
    if (!contentUsageId) return
    contentUsageDrawer.open({
      drawerContentUsageId: contentUsageId,
      contentTemplate,
    })
  }
}

export default observer(ContentAppSetupModalContent)
