import AppSettingsForm from "@/apps/list/app/common/settings/AppSettingsForm"
import { useAppLevel } from "@/apps/util/appLevelContext"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { generatePath, useHistory } from "react-router-dom"

interface Props extends TestIDProps {
  appId?: GlobalID
  onClose: () => void
  onBack: () => void
}

function CollectionSettingsForm({ testid, appId, onClose, onBack }: Props) {
  const { navFolderId, navSectionId } = useAppLevel()
  const history = useHistory()

  const createForm = useAddAppFormStore({
    kind: "collection",
    customAppTitle: "Collection",
    navFolderId,
    navSectionId,
    visibility: "all",
    visibilityGroups: [],
    visibilityMembers: [],
    badge: {
      kind: "icon",
      icon: "layers-three",
      color: "#ffffff00",
    },
  })
  const editForm = useEditAppFormStore(appId)

  const form = appId ? editForm : createForm

  return (
    <AppSettingsForm
      testid={testid}
      form={form}
      mode={appId ? "edit" : "add"}
      onSubmit={appId ? handleEditApp : handleAddApp}
      onClose={onBack}
    />
  )

  async function handleAddApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        ...state
      } = createForm.state

      const { didSave, response } = await createForm.submit(state, {
        connections: [],
        variables: { isOrgTopLevel: !state.productId && !state.navSectionId },
      })

      if (!didSave || !response?.node) return

      displaySuccessToast({
        message: "Collection created!",
        testid: "NewCollectionForm.success-toast",
      })

      onClose()

      if (response.node.collection?.id) {
        redirectToCollection(response.node.collection.id, response.node.product?.slug)
      }
    } catch (error) {
      displayErrorToast(error)
    }
  }

  async function handleEditApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        kind: _kind,
        ...changedState
      } = editForm.changedState
      const { didSave, response } = await editForm.submit({
        id: editForm.state.id,
        ...changedState,
        badge: editForm.state.badge,
        visibility: editForm.state.visibility,
        visibilityGroups: editForm.state.visibilityGroups,
        visibilityMembers: editForm.state.visibilityMembers,
      })

      if (!didSave || !response?.node) return

      displaySuccessToast({
        message: "Collection updated!",
        testid: "AddApp.success-toast",
      })

      onClose()
      if (response.node.collection?.id) {
        redirectToCollection(response.node.collection.id, response.node.product?.slug)
      }
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function redirectToCollection(collectionId: GlobalID, productSlug?: string | null) {
    // Don't redirect if we're on the collection's page already
    if (location.pathname.includes(collectionId)) return

    if (productSlug)
      history.push(
        generatePath(ROUTE_NAMES.PRODUCT.COLLECTION.DETAIL, {
          productSlug,
          collectionId,
        })
      )
    else
      history.push(
        generatePath(ROUTE_NAMES.COMMUNITY.COLLECTION.DETAIL, {
          collectionId,
        })
      )
  }
}

export default observer(CollectionSettingsForm)
