/**
 * @generated SignedSource<<96a86f317ee2c7effcc2a5730fdd914d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LinkAppSettingsButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"LinkAppSetupModalFragment">;
  readonly " $fragmentType": "LinkAppSettingsButtonFragment";
};
export type LinkAppSettingsButtonFragment$key = {
  readonly " $data"?: LinkAppSettingsButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LinkAppSettingsButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkAppSettingsButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LinkAppSetupModalFragment"
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "b710f0aae8a9e680829f61bc2ce09d78";

export default node;
