import { AppFormGroupsSectionQuery } from "@/apps/list/app/common/settings/__generated__/AppFormGroupsSectionQuery.graphql"
import MemberGroupsMultiSelect, {
  MemberGroupsMultiSelectProps,
} from "@/product/common/member-group/modal/components/MemberGroupsMultiSelect"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import {
  DiscoFormControl,
  DiscoFormControlProps,
  DiscoInputSkeleton,
  DiscoText,
} from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props
  extends Pick<MemberGroupsMultiSelectProps, "filterGroups">,
    TestIDProps,
    Pick<DiscoFormControlProps, "errorMessages"> {
  onChange: (memberGroupIds: GlobalID[]) => void
  memberGroupIds: GlobalID[]
  productId?: GlobalID | null
  organizationId: GlobalID
  hideAdminOnlyGroups?: boolean
  hideCommunityGroups?: boolean
  disabled?: boolean
  label?: string
  tooltip?: string
  allowChildGroupSelection?: boolean
}

function AppFormGroupsSection({
  testid = "AppFormGroupsSection",
  memberGroupIds,
  productId,
  organizationId,
  hideAdminOnlyGroups = false,
  hideCommunityGroups = false,
  onChange,
  filterGroups,
  errorMessages,
  disabled = false,
  label = "Add Groups",
  tooltip,
  allowChildGroupSelection = false,
}: Props) {
  const { product, organization } = useLazyLoadQuery<AppFormGroupsSectionQuery>(
    graphql`
      query AppFormGroupsSectionQuery($organizationId: ID!, $productId: ID!) {
        product: node(id: $productId) {
          ... on Product {
            id
            ...MemberGroupsMultiSelect_ProductFragment
            organization {
              ...MemberGroupsMultiSelect_OrganizationFragment
            }
          }
        }
        organization: node(id: $organizationId) {
          ... on Organization {
            id
            ...MemberGroupsMultiSelect_OrganizationFragment
          }
        }
      }
    `,
    {
      organizationId,
      productId: productId || "",
    },
    { fetchPolicy: "network-only" }
  )

  return (
    <DiscoFormControl
      label={
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {label}
        </DiscoText>
      }
      marginBottom={2}
      errorMessages={errorMessages}
      tooltip={tooltip}
    >
      <MemberGroupsMultiSelect
        testid={`${testid}.group-select`}
        selectedGroupIds={memberGroupIds}
        setSelectedGroupIds={onChange}
        productKey={product}
        organizationKey={product?.organization || organization!}
        allowGroupSelection={["custom", "role", "default"]}
        hideAdminOnlyGroups={hideAdminOnlyGroups}
        hideCommunityGroups={hideCommunityGroups}
        filterGroups={filterGroups}
        disabled={disabled}
        allowChildGroupSelection={allowChildGroupSelection}
      />
    </DiscoFormControl>
  )
}

export const AppFormGroupsSectionSkeleton = () => {
  return (
    <DiscoFormControl
      label={
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {`Add Groups`}
        </DiscoText>
      }
      marginBottom={0}
    >
      <DiscoInputSkeleton />
    </DiscoFormControl>
  )
}

export default Relay.withSkeleton({
  component: observer(AppFormGroupsSection),
  skeleton: AppFormGroupsSectionSkeleton,
})
