import AppListItem from "@/apps/list/AppListItem"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { APP_KIND_ICON } from "@/apps/util/appConstants"
import { TestIDProps } from "@utils/typeUtils"

function GroupDirectoryApp({ testid = "GroupDirectoryApp" }: TestIDProps) {
  const { setOpenModalState } = useActiveAppModal()

  return (
    <AppListItem
      testid={`${testid}.group-directory`}
      title={"Group Directory"}
      description={"Showcase groups and their members in a directory."}
      icon={APP_KIND_ICON.group_directory}
      onClick={handleOpenModal}
    />
  )

  function handleOpenModal() {
    setOpenModalState({
      kind: "group_directory",
    })
  }
}

export default GroupDirectoryApp
