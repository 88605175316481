import { RemoveAppButtonFragment$key } from "@/apps/remove-app-button/__generated__/RemoveAppButtonFragment.graphql"
import { RemoveAppButtonMutation } from "@/apps/remove-app-button/__generated__/RemoveAppButtonMutation.graphql"
import { useAppLabel } from "@/apps/util/appUtil"
import AppUtils from "@/apps/util/AppUtils"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { useProductSlug } from "@/core/route/util/routeUtils"
import Relay from "@/relay/relayUtils"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoConfirmationModal from "@disco-ui/modal/DiscoConfirmationModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { useState } from "react"
import { useFragment } from "react-relay"
import { generatePath, useHistory, useLocation } from "react-router-dom"
import { ConnectionHandler, graphql } from "relay-runtime"

interface RemoveAppButtonProps {
  appKey: RemoveAppButtonFragment$key
  children: OverridableDiscoButtonChildren
}

function RemoveAppButton({ appKey, children, ...props }: RemoveAppButtonProps) {
  const app = useFragment<RemoveAppButtonFragment$key>(
    graphql`
      fragment RemoveAppButtonFragment on ProductApp {
        id
        kind
        customAppTitle
        feed {
          id
          name
        }
        collection {
          id
        }
        productId
        organizationId
        navSectionId
      }
    `,
    appKey
  )

  const [isLoading, setIsLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const activeProductSlug = useProductSlug()
  const history = useHistory()
  const location = useLocation()
  const activeOrganization = useActiveOrganization()!
  const curriculumLabel = useLabel("curriculum")

  const removeAppMutation = Relay.useAsyncMutation<RemoveAppButtonMutation>(
    graphql`
      mutation RemoveAppButtonMutation(
        $id: ID!
        $isEventsApp: Boolean!
        $isOrgCollectionApp: Boolean!
        $connections: [ID!]!
      ) {
        deleteProductApp(id: $id) {
          node {
            id @deleteEdge(connections: $connections) @deleteRecord
            product {
              id
              ...MyExperiencesListItemFragment
              dashboard {
                id
                blocks(position: main) {
                  edges {
                    node {
                      id
                      kind
                      ...DashboardBlockItemFragment
                    }
                  }
                }
              }
              curriculum {
                ...CurriculumModuleListFragment
              }
            }
            organization {
              dashboard @include(if: $isOrgCollectionApp) {
                id
                mainBlocks: blocks(position: main) {
                  edges {
                    node {
                      id
                      kind
                      ...DashboardBlockItemFragment
                    }
                  }
                }
                sideBlocks: blocks(position: side) {
                  edges {
                    node {
                      id
                      kind
                      ...DashboardBlockItemFragment
                    }
                  }
                }
              }
              viewerMembership @include(if: $isEventsApp) {
                ...useViewerCanCreateEventsFragment
              }
              occurrences @include(if: $isEventsApp) {
                totalCount
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `
  )

  const appLabel = useAppLabel(app)

  return (
    <>
      <OverridableDiscoButton onClick={onOpen} {...props}>
        {children}
      </OverridableDiscoButton>

      <DiscoConfirmationModal
        modalContentLabel={"Remove App Dialog"}
        testid={"RemoveAppButton.delete-warning-modal"}
        isOpen={isOpen}
        onClose={onClose}
        title={`Are you sure you want to delete the ${appLabel} app?`}
        description={getConfirmationModalDescription()}
        confirmButtonProps={{
          children: `Yes, remove this app`,
          onClick: removeApp,
          shouldDisplaySpinner: isLoading,
        }}
        typeToConfirmText={app.customAppTitle || appLabel}
      />
    </>
  )

  function getConfirmationModalDescription() {
    if (app.kind === "curriculum") {
      return `This will permanently delete the ${curriculumLabel.singular} and all the content inside it. You will not be able to undo this. Are you sure you want to proceed?`
    } else if (app.kind === "events") {
      return "This will permanently delete all of your events and you will not be able to undo this. Are you sure you want to delete all your events?"
    }
    return "This will permanently delete the app and all of its contents. Are you sure you want to proceed?"
  }

  async function removeApp() {
    setIsLoading(true)
    try {
      const { deleteProductApp } = await removeAppMutation(
        {
          id: app.id,
          isEventsApp: app.kind === "events",
          isOrgCollectionApp: app.kind === "collection" && !app.productId,
          connections: AppUtils.getAppConnectionIds(
            app.organizationId,
            app.productId,
            app.navSectionId
          ),
        },
        {
          updater: (store, response) => {
            if (!response.deleteProductApp.node) return
            if (app.feed?.id) {
              const { product } = response.deleteProductApp.node
              const feedRecord = store.get(product?.id || activeOrganization.id)
              if (!feedRecord) return
              const feedConnectionRecord = ConnectionHandler.getConnection(
                feedRecord,
                product?.id
                  ? "ProductFeedSelectorDropdown_feeds"
                  : "OrganizationFeedSelectorDropdown_feeds"
              )
              if (!feedConnectionRecord) return
              ConnectionHandler.deleteNode(feedConnectionRecord, app.feed!.id)
            }
          },
        }
      )
      if (deleteProductApp.errors?.length)
        throw new Error(deleteProductApp.errors[0].message)

      displayToast({ message: "App removed" })

      // Redirect user if currently on the removed app's route
      handleRedirectOnRemove()
    } catch (error) {
      displayErrorToast(error)
      setIsLoading(false)
    }
  }

  function handleRedirectOnRemove() {
    // Handle removing product level apps
    if (activeProductSlug) {
      if (
        location.pathname.includes(app.id) ||
        (app.kind === "curriculum" &&
          location.pathname.includes(
            generatePath(ROUTE_NAMES.PRODUCT.CURRICULUM.ROOT, {
              productSlug: activeProductSlug,
            })
          )) ||
        (app.kind === "events" &&
          location.pathname.includes(
            generatePath(ROUTE_NAMES.PRODUCT.EVENTS.LIST.ROOT, {
              productSlug: activeProductSlug,
            })
          )) ||
        // If we're viewing the feed or a post from the feed
        (app.kind === "posts" &&
          app.feed?.id &&
          location.pathname.includes(
            generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
              productSlug: activeProductSlug,
              feedId: app.feed.id,
            })
          )) ||
        (app.kind === "collection" &&
          location.pathname.includes(
            generatePath(ROUTE_NAMES.PRODUCT.COLLECTION.ROOT, {
              productSlug: activeProductSlug,
            })
          ))
      ) {
        redirectToProductDashboard(activeProductSlug)
      }
    } else if (
      location.pathname.includes(app.id) ||
      (app.kind === "collection" &&
        app.collection?.id &&
        location.pathname.includes(
          generatePath(ROUTE_NAMES.COMMUNITY.COLLECTION.DETAIL, {
            collectionId: app.collection.id,
          })
        )) ||
      (app.kind === "posts" &&
        app.feed?.id &&
        location.pathname.includes(
          generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, {
            feedId: app.feed.id,
          })
        ))
    ) {
      // Handle removing organization level apps
      history.replace(ROUTE_NAMES.COMMUNITY.HOME.ROOT)
    }
  }

  function redirectToProductDashboard(productSlug: string) {
    history.replace(
      generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
        productSlug,
      })
    )
  }
}

export default RemoveAppButton
