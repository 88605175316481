/**
 * @generated SignedSource<<012081e3581a648407425a25f84e31b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ChatChannelSettingsDrawerContentQuery$variables = {
  id: string;
};
export type ChatChannelSettingsDrawerContentQuery$data = {
  readonly app: {
    readonly customAppTitle?: string | null;
  } | null;
};
export type ChatChannelSettingsDrawerContentQuery = {
  response: ChatChannelSettingsDrawerContentQuery$data;
  variables: ChatChannelSettingsDrawerContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customAppTitle",
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatChannelSettingsDrawerContentQuery",
    "selections": [
      {
        "alias": "app",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatChannelSettingsDrawerContentQuery",
    "selections": [
      {
        "alias": "app",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "87d413c9cea8a9034fd7adf0f22f1f14",
    "id": null,
    "metadata": {},
    "name": "ChatChannelSettingsDrawerContentQuery",
    "operationKind": "query",
    "text": "query ChatChannelSettingsDrawerContentQuery(\n  $id: ID!\n) {\n  app: node(id: $id) {\n    __typename\n    ... on ProductApp {\n      customAppTitle\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4acc308e7e7cc5ae2ff7bc7da1b2285f";

export default node;
