/**
 * @generated SignedSource<<216a07129812b20bcc3c9ffe3838af5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatChannelSettingsModalButtonFragment$data = {
  readonly id: string;
  readonly productId: string | null;
  readonly " $fragmentType": "ChatChannelSettingsModalButtonFragment";
};
export type ChatChannelSettingsModalButtonFragment$key = {
  readonly " $data"?: ChatChannelSettingsModalButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelSettingsModalButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelSettingsModalButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "d2cea7ae53ab37ff986ca4572eacf7ba";

export default node;
