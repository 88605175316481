import AppSettingsForm from "@/apps/list/app/common/settings/AppSettingsForm"
import AppUrlFormSection from "@/apps/list/form-sections/AppUrlFormSection"
import { LegacyCreateSetupAppModalFormStore } from "@/apps/list/modal/SetupAppModal"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import ProductSelect from "@/product/common/ProductSelect"
import { GlobalID } from "@/relay/RelayTypes"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoFormControl, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  testid: string
  appId?: GlobalID
  isProductLink: boolean
  description?: string
  createForm: LegacyCreateSetupAppModalFormStore
}

function LinkAppSetupModalContent({
  testid,
  appId,
  isProductLink,
  description,
  createForm,
}: Props) {
  const { closeModal, setOpenModalState } = useActiveAppModal()
  const productLabel = useLabel("admin_experience")
  const activeOrganization = useActiveOrganization()!
  const editForm = useEditAppFormStore(appId)
  const form = appId ? editForm : createForm

  return (
    <>
      {description && (
        <DiscoText
          data-testid={`${testid}.link.subtitle`}
          variant={"body-sm"}
          color={"text.secondary"}
          marginBottom={3}
        >
          {description}
        </DiscoText>
      )}
      <AppSettingsForm
        form={form}
        mode={appId ? "edit" : "add"}
        onClose={appId ? closeModal : () => setOpenModalState({ kind: "add-app" })}
        onSubmit={appId ? handleEditApp : handleAddApp}
        hideIconAndTitle={isProductLink}
        visibilityConfig={{ hide: isProductLink }}
        formSectionBody={
          isProductLink ? (
            <DiscoFormControl
              label={<DiscoText variant={"body-sm"}>{productLabel.singular}</DiscoText>}
              errorMessages={form.errorsByField.linkProductId}
            >
              <ProductSelect
                testid={`${testid}.link.product`}
                organizationId={activeOrganization.id}
                value={form.state.linkProductId}
                onChange={(id) => (form.state.linkProductId = id)}
                hideNonPublic={false}
              />
            </DiscoFormControl>
          ) : (
            <AppUrlFormSection form={form} placeholder={"link.com"} />
          )
        }
      />
    </>
  )

  async function handleAddApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        ...state
      } = createForm.state
      const { didSave, response } = await createForm.submit(state, {
        connections: [],
        variables: { isOrgTopLevel: !state.productId && !state.navSectionId },
      })
      if (!didSave || !response?.node) return
      displaySuccessToast({
        message: `Link created!`,
        testid: `${testid}.link.success-message`,
      })
      closeModal()
    } catch (error) {
      displayErrorToast(error)
    }
  }

  async function handleEditApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        kind: _kind,
        ...changedState
      } = editForm.changedState
      const { didSave, response } = await editForm.submit({
        id: editForm.state.id,
        ...changedState,
        badge: editForm.state.badge,
        visibility: editForm.state.visibility,
        visibilityGroups: editForm.state.visibilityGroups,
        visibilityMembers: editForm.state.visibilityMembers,
      })
      if (!didSave || !response?.node) return
      displaySuccessToast({
        message: "Updated app!",
        testid: "AddApp.success-toast",
      })
      closeModal()
    } catch (error) {
      displayErrorToast(error)
    }
  }
}

export default observer(LinkAppSetupModalContent)
