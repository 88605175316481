import { FeedAllowPostsFrom } from "@/apps/actions/__generated__/ToggleAppStatusButtonMutation.graphql"
import AppFormGroupsSection from "@/apps/list/app/common/settings/AppFormGroupsSection"
import AppFormMembersSection from "@/apps/list/app/common/settings/AppFormMembersSection"
import AppDescriptionFormSection from "@/apps/list/form-sections/AppDescriptionFormSection"
import AppFormDashboardBlockSection from "@/apps/list/form-sections/AppFormDashboardBlockSection"
import AppSubmitFormSection from "@/apps/list/form-sections/AppSubmitFormSection"
import LegacyAppIconAndTitleFormSection from "@/apps/list/form-sections/LegacyAppIconAndTitleFormSection"
import { useActiveAppModal } from "@/apps/util/activeAppModalContext"
import { useAppLevel } from "@/apps/util/appLevelContext"
import AppUtils from "@/apps/util/AppUtils"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { GlobalID } from "@/relay/RelayTypes"
import Form from "@components/form/Form"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoFormControl, DiscoSection, DiscoSelect, DiscoText } from "@disco-ui"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { generatePath, useHistory } from "react-router-dom"
import AppVisibilityForm from "../../common/settings/AppVisibilityForm"

interface Props {
  testid: string
  appId?: GlobalID
}

function FeedAppSetupModalContent({ testid, appId }: Props) {
  const { closeModal, setOpenModalState } = useActiveAppModal()
  const { product, navFolderId } = useAppLevel()
  const activeOrganization = useActiveOrganization()!
  const history = useHistory()

  const createForm = useAddAppFormStore({
    kind: "posts",
    customAppTitle: "",
    customAppDescription: "",
    navFolderId,
    badge: {
      kind: "icon",
      icon: "widget-alt",
      color: "#ffffff00",
    },
    visibility: "all",
    visibilityGroups: [],
    visibilityMembers: [],
    allowPostsFrom: "admins",
    allowPostsGroups: [],
    allowPostsMembers: [],
  })
  const editForm = useEditAppFormStore(appId)

  const form = appId ? editForm : createForm
  const productId = product?.id
  const isProductLevelApp = Boolean(product)

  return (
    <Form
      testid={"AppSetupModalForm"}
      onSubmit={appId ? handleEditApp : handleAddApp}
      buttons={
        <AppSubmitFormSection
          form={form}
          mode={appId ? "edit" : "add"}
          isSubmitDisabled={false}
          onClose={appId ? closeModal : () => setOpenModalState({ kind: "add-app" })}
        />
      }
    >
      <LegacyAppIconAndTitleFormSection form={form} placeholder={"Announcements"} />

      <AppDescriptionFormSection
        kind={"posts"}
        value={form.state.customAppDescription || ""}
        onChange={(description) => (form.state.customAppDescription = description)}
        errorMessages={form.errorsByField.customAppDescription}
      />

      {isProductLevelApp && <AppFormDashboardBlockSection legacyForm={form} />}

      <AppVisibilityForm form={form} />

      {/** Forum visibility settings */}
      {form.state.visibility === "membership" && (
        <DiscoSection
          groovyDepths={"insideCard"}
          padding={1.5}
          marginTop={-1}
          marginLeft={0.25}
          marginRight={0.25}
          marginBottom={2.5}
        >
          <AppFormGroupsSection
            testid={`${testid}.access`}
            memberGroupIds={toJS(form.state.visibilityGroups!)}
            productId={productId}
            organizationId={activeOrganization.id}
            onChange={(groupIds) => form.state.visibilityGroups!.replace(groupIds)}
            errorMessages={form.errorsByField.visibilityGroups}
            hideCommunityGroups={isProductLevelApp ? true : undefined}
          />
          <AppFormMembersSection
            testid={`${testid}.access`}
            membershipIds={toJS(form.state.visibilityMembers!)}
            productId={productId}
            onChange={(memberships) =>
              form.state.visibilityMembers!.replace(memberships.map((m) => m.id))
            }
            errorMessages={form.errorsByField.visibilityMembers}
          />
        </DiscoSection>
      )}

      <DiscoFormControl
        label={<DiscoText variant={"body-sm"}>{"Who can post?"}</DiscoText>}
        errorMessages={form.errorsByField.allowPostsFrom}
      >
        <DiscoSelect
          value={form.state.allowPostsFrom}
          testid={`${testid}.allow-posts-from-select`}
          disableClearable
          options={[
            {
              value: "all",
              title: "Everyone",
            },
            {
              value: "admins",
              title: productId ? "Managers & Instructors" : "Admins & Owners",
            },
            {
              value: "groups",
              title: "Custom",
            },
          ]}
          onChange={(v) => (form.state.allowPostsFrom = v as FeedAllowPostsFrom)}
        />
      </DiscoFormControl>
      {form.state.allowPostsFrom === "groups" && (
        <DiscoSection
          groovyDepths={"insideCard"}
          padding={1.5}
          marginTop={-1}
          marginLeft={0.25}
          marginRight={0.25}
          marginBottom={2.5}
        >
          {/* Forum allow posts groups (targeting specific groups) */}
          <AppFormGroupsSection
            testid={`${testid}.allow-posts-from`}
            memberGroupIds={toJS(form.state.allowPostsGroups!)}
            productId={productId}
            organizationId={activeOrganization.id}
            onChange={(groupIds) => form.state.allowPostsGroups!.replace(groupIds)}
            errorMessages={form.errorsByField.allowPostsGroups}
            hideCommunityGroups={isProductLevelApp ? true : undefined}
          />

          {/* Forum allow posts users (targeting individual users) */}
          <AppFormMembersSection
            testid={`${testid}.allow-posts-from`}
            membershipIds={toJS(form.state.allowPostsMembers!)}
            productId={productId}
            onChange={(membershipIds) =>
              form.state.allowPostsMembers!.replace(membershipIds.map((m) => m.id))
            }
            errorMessages={form.errorsByField.allowPostsMembers}
          />
        </DiscoSection>
      )}
    </Form>
  )

  async function handleAddApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        ...state
      } = createForm.state
      const { didSave, response } = await createForm.submit(state, {
        connections: AppUtils.getFeedConnections(activeOrganization?.id, productId),
        variables: { isOrgTopLevel: !state.productId && !state.navSectionId },
      })
      if (!didSave) return
      displaySuccessToast({
        message: "Feed app created!",
        testid: "AddApp.success-toast",
      })

      // If the modal is rendered outside of the ActiveAppModalContext then this function won't be available
      if (closeModal) {
        closeModal()
      }

      if (response?.node?.feed?.id) {
        redirectToFeed(response?.node?.feed.id, product?.slug)
      }
    } catch (error) {
      displayErrorToast(error)
    }
  }

  async function handleEditApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        kind: _kind,
        ...changedState
      } = editForm.changedState
      const { didSave, response } = await editForm.submit({
        id: editForm.state.id,
        ...changedState,
        badge: editForm.state.badge,
        showOnDashboard: editForm.state.showOnDashboard,
        visibility: editForm.state.visibility,
        visibilityGroups: editForm.state.visibilityGroups,
        visibilityMembers: editForm.state.visibilityMembers,
        allowPostsFrom: editForm.state.allowPostsFrom,
        allowPostsGroups: editForm.state.allowPostsGroups,
        allowPostsMembers: editForm.state.allowPostsMembers,
      })
      if (!didSave) return
      displaySuccessToast({
        message: "Updated app!",
        testid: "AddApp.success-toast",
      })
      closeModal()
      if (response?.node?.feed?.id) {
        redirectToFeed(response?.node?.feed.id, product?.slug)
      }
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function redirectToFeed(feedId: GlobalID, productSlug?: string | null) {
    if (productSlug) {
      history.push(
        generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
          productSlug,
          feedId,
        })
      )
    } else {
      history.push(
        generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, {
          feedId,
        })
      )
    }
  }
}

export default observer(FeedAppSetupModalContent)
