import { ChatChannelFormGroupsSectionQuery } from "@/apps/list/app/chat/form/sections/__generated__/ChatChannelFormGroupsSectionQuery.graphql"
import FormStore from "@/core/form/store/FormStore"
import MemberGroupsMultiSelect from "@/product/common/member-group/modal/components/MemberGroupsMultiSelect"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoFormControl, DiscoInputSkeleton, DiscoText } from "@disco-ui"
import { observable, toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  form: FormStore<{ memberGroupIds: GlobalID[] }>
  productId?: GlobalID | null
  organizationId: GlobalID
}

function ChatChannelFormGroupsSection({ form, productId, organizationId }: Props) {
  const { product, organization } = useLazyLoadQuery<ChatChannelFormGroupsSectionQuery>(
    graphql`
      query ChatChannelFormGroupsSectionQuery($organizationId: ID!, $productId: ID!) {
        product: node(id: $productId) {
          ... on Product {
            id
            defaultMemberGroups: memberGroups(kind: default) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            ...MemberGroupsMultiSelect_ProductFragment
            organization {
              ...MemberGroupsMultiSelect_OrganizationFragment
            }
          }
        }
        organization: node(id: $organizationId) {
          ... on Organization {
            id
            defaultMemberGroups: memberGroups(kind: default) {
              edges {
                node {
                  id
                  name
                }
              }
            }
            ...MemberGroupsMultiSelect_OrganizationFragment
          }
        }
      }
    `,
    {
      organizationId,
      productId: productId || "",
    },
    { fetchPolicy: "network-only" }
  )

  const systemGroups = Relay.connectionToArray(
    product?.defaultMemberGroups || organization?.defaultMemberGroups
  )
  const everyoneGroup = systemGroups.find((group) => group.name === "Everyone")

  return (
    <DiscoFormControl
      label={
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {`Add Groups`}
        </DiscoText>
      }
      marginBottom={2}
    >
      <MemberGroupsMultiSelect
        selectedGroupIds={toJS(form.state.memberGroupIds)}
        setSelectedGroupIds={handleSelectedGroupIds}
        productKey={product}
        organizationKey={product?.organization || organization!}
        disableMemberGroupIds={[everyoneGroup!.id]}
        allowGroupSelection={["custom", "default", "role"]}
        hideAdminOnlyGroups
      />
    </DiscoFormControl>
  )

  function handleSelectedGroupIds(selectedMemberGroupIds: string[]) {
    if (!form.state.memberGroupIds) {
      form.state.memberGroupIds = observable.array()
    }
    form.state.memberGroupIds.replace(selectedMemberGroupIds)
  }
}

export const ChatChannelFormGroupsSectionSkeleton = () => {
  return (
    <DiscoFormControl
      label={
        <DiscoText variant={"body-sm"} color={"text.secondary"}>
          {`Add Groups`}
        </DiscoText>
      }
      marginBottom={0}
    >
      <DiscoInputSkeleton />
    </DiscoFormControl>
  )
}

export default Relay.withSkeleton({
  component: observer(ChatChannelFormGroupsSection),
  skeleton: ChatChannelFormGroupsSectionSkeleton,
})
