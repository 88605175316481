/**
 * @generated SignedSource<<c2023059314c4d57bcbf1c8696d867e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MembersAppSettingsButtonFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MembersSettingsModalFragment">;
  readonly " $fragmentType": "MembersAppSettingsButtonFragment";
};
export type MembersAppSettingsButtonFragment$key = {
  readonly " $data"?: MembersAppSettingsButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MembersAppSettingsButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembersAppSettingsButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MembersSettingsModalFragment"
    }
  ],
  "type": "ProductApp",
  "abstractKey": null
};

(node as any).hash = "cc9081756174220b1c3b814dbfb08812";

export default node;
