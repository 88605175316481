import AppFormGroupsSection from "@/apps/list/app/common/settings/AppFormGroupsSection"
import AppSettingsForm from "@/apps/list/app/common/settings/AppSettingsForm"
import { useAppLevel } from "@/apps/util/appLevelContext"
import useAddAppFormStore from "@/apps/util/hooks/useAddAppFormStore"
import useEditAppFormStore from "@/apps/util/hooks/useEditAppFormStore"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import { TestIDProps } from "@utils/typeUtils"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { commitLocalUpdate } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import ConnectionHandler from "relay-connection-handler-plus"

interface Props extends TestIDProps {
  appId?: GlobalID
  onClose: () => void
  onBack: () => void
}

function GroupDirectoryAppSettingsForm({
  testid = "GroupDirectoryAppSettingsForm",
  appId,
  onClose,
  onBack,
}: Props) {
  const activeOrganization = useActiveOrganization()!
  const { navFolderId, navSectionId, product } = useAppLevel()
  const history = useHistory()

  const createForm = useAddAppFormStore({
    kind: "group_directory",
    customAppTitle: "Group Directory",
    navFolderId,
    navSectionId,
    visibility: "all",
    visibilityGroups: [],
    visibilityMembers: [],
    badge: {
      kind: "icon",
      icon: "group-share",
      color: "#ffffff00",
    },
    appDirectoryItems: [],
  })
  const editForm = useEditAppFormStore(appId)

  const form = appId ? editForm : createForm
  const appDirectoryItems = form.state.appDirectoryItems!

  return (
    <AppSettingsForm
      testid={testid}
      form={form}
      mode={appId ? "edit" : "add"}
      formSectionBody={
        <AppFormGroupsSection
          testid={`${testid}.featured-groups`}
          label={"Featured Groups"}
          tooltip={"Select groups to feature in the group directory app."}
          allowChildGroupSelection
          memberGroupIds={toJS(appDirectoryItems.map((item) => item.memberGroupId))}
          organizationId={activeOrganization.id}
          productId={product?.id}
          onChange={(groupIds) =>
            appDirectoryItems?.replace(groupIds.map((id) => ({ memberGroupId: id })))
          }
          hideAdminOnlyGroups
          hideCommunityGroups={false}
          filterGroups={(group) =>
            // Only show community groups for community scoped directory apps, or groups for the same product if product scoped directory app
            group.filter((g) => !g.product || g.product.id === product?.id)
          }
        />
      }
      onSubmit={appId ? handleEditApp : handleAddApp}
      onClose={onBack}
    />
  )

  async function handleAddApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        ...state
      } = createForm.state

      const { didSave, response } = await createForm.submit(
        {
          ...state,
          // If items array empty, pass null to create app without items
          // Otherwise, provide array input to be validated
          appDirectoryItems: appDirectoryItems.length === 0 ? null : appDirectoryItems,
        },
        {
          connections: [],
          variables: { isOrgTopLevel: !state.productId && !state.navSectionId },
        }
      )

      if (!didSave || !response?.node) return

      displaySuccessToast({
        message: "Group Directory created!",
        testid: `${testid}.success-toast`,
      })

      onClose()
      if (!response.node.id) return
      redirectToGroupDirectory(response.node.id, response.node.product?.slug)
    } catch (error) {
      displayErrorToast(error)
    }
  }

  async function handleEditApp() {
    try {
      const {
        embedCode: _embedCode,
        scormFileId: _scormFileId,
        kind: _kind,
        appDirectoryItems: changedItems,
        ...changedState
      } = editForm.changedState

      const { didSave, response } = await editForm.submit({
        id: editForm.state.id,
        ...changedState,
        // No items provided, delete all existing items
        // Otherwise, provide updated items, or undefined to skip update
        appDirectoryItems: changedItems?.length === 0 ? null : changedItems,
        badge: editForm.state.badge,
        visibility: editForm.state.visibility,
        visibilityGroups: editForm.state.visibilityGroups,
        visibilityMembers: editForm.state.visibilityMembers,
      })

      if (!didSave || !response?.node) return

      commitLocalUpdate(RelayEnvironment, (store) => {
        const appRecord = store.get(appId!)
        if (!appRecord) return
        ConnectionHandler.getConnections(
          appRecord,
          "GroupDirectoryList_appDirectoryItems"
        ).forEach((connection) => connection.invalidateRecord())
      })

      displaySuccessToast({
        message: "Group Directory updated!",
        testid: `AddApp.success-toast`,
      })

      onClose()
      if (!response.node.id) return
      redirectToGroupDirectory(response.node.id, response.node.product?.slug)
    } catch (error) {
      displayErrorToast(error)
    }
  }

  function redirectToGroupDirectory(dirAppId: GlobalID, productSlug?: string | null) {
    // Don't redirect if we're on the group directory page already
    if (location.pathname.includes(dirAppId)) return

    if (productSlug)
      history.push(
        generatePath(ROUTE_NAMES.PRODUCT.GROUP_DIRECTORY.DETAIL, {
          productSlug,
          groupDirectoryId: dirAppId,
        })
      )
    else
      history.push(
        generatePath(ROUTE_NAMES.COMMUNITY.GROUP_DIRECTORY.DETAIL, {
          groupDirectoryId: dirAppId,
        })
      )
  }
}

export default observer(GroupDirectoryAppSettingsForm)
